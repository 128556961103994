import React, { useEffect, useRef, useState } from 'react';
import Button from './Button';

export default function DeleteListConfirmationDialog({
  isOpen,
  onClose,
  onSubmit,
  listName,
}: Readonly<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (inputValue: string) => void;
  listName: string;
}>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleOnClose = () => {
    setInputValue('');
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(inputValue);
    handleOnClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-70 overflow-y-scroll z-30 flex justify-center items-start p-4">
      <div ref={inputRef} className="bg-white p-6 relative w-full lg:max-w-[75%] xl:max-w-[50%]">
        <button className="absolute top-3 right-3 p-3" onClick={handleOnClose}>
          <img src="/icons/close-blue.svg" alt="Close" />
        </button>
        <h2 className="font-mmc text-2xl pb-4 mb-4 text-ow-primary border-b border-ow-light-grey">Delete list {listName}?</h2>

        <div className="flex">
          <div className="w-1/2 pr-4">
            <Button gray={true} text="Yes" type="button" onClick={handleSubmit} />
          </div>
          <div className="w-1/2 pl-4">
            <Button text="No" type="button" onClick={handleOnClose} />
          </div>
        </div>
      </div>
    </div>
  );
}
