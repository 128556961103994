import { atom } from 'recoil';
import { ICompanySummary, IListStatistics } from '../types/company';
import { ComparisonEnum } from '../constants/compare';
import { CompanyListEnum } from '../constants/company-list';

export const companiesAtom = atom<ICompanySummary[]>({
  key: 'companies',
  default: [],
});

export const loadingCompaniesAtom = atom<boolean>({
  key: 'loadingCompanies',
  default: false,
});

export const companiesErrorAtom = atom<boolean>({
  key: 'companiesError',
  default: false,
});

export const dailyWeeklyAtom = atom<string>({
  key: 'dailyWeekly',
  default: 'WEEKLY',
});

export const listsAtom = atom<string[]>({
  key: 'lists',
  default: [],
});

export const userListStatsAtom = atom<IListStatistics[]>({
  key: 'userListStats',
  default: [],
});

export const compareSelectedAtom = atom<ComparisonEnum>({
  key: 'compareSelected',
  default: ComparisonEnum.Table,
});

export const companyListSelectorAtom = atom<CompanyListEnum>({
  key: 'companyListSelector',
  default: CompanyListEnum.Company,
});

// export const filterMyListsAtom = atom<string[]>({
//   key: 'filterMyLists',
//   default: [],
// });

// export const filterIndustriesAtom = atom<string[]>({
//   key: 'filterIndustries',
//   default: [],
// });

// export const filterCountriesAtom = atom<string[]>({
//   key: 'filterCountries',
//   default: [],
// });
