import { useRecoilState, useRecoilValue } from 'recoil';
import { companyListSelectorAtom, companiesAtom, userListStatsAtom } from '../store/portfolio';
import { CompanyListEnum } from '../constants/company-list';
import { ICompanySummary, IListStatistics } from '../types/company';

export function CompanyListSelector() {
  const companies = useRecoilValue<ICompanySummary[]>(companiesAtom);
  const listStatistics = useRecoilValue<IListStatistics[]>(userListStatsAtom);

  const [companyListSelector, setCompanyListSelector] = useRecoilState<CompanyListEnum>(companyListSelectorAtom);

  const tableClassName = `pr-[17px] ${companyListSelector === CompanyListEnum.Company ? 'text-ow-secondary' : 'black'}`;
  const graphClassName = `pr-[17px] ${companyListSelector === CompanyListEnum.List ? 'text-ow-secondary' : 'black'}`;

  return (
    <div className="container px-6 mx-auto pb-4">
      <div className="flex justify-start  font-bold">
        <button className={tableClassName} onClick={() => setCompanyListSelector(CompanyListEnum.Company)}>
          <div className="flex">
            <div className="uppercase pr-[5px]">Companies ({companies.length})</div>
          </div>
        </button>
        <button className={graphClassName} onClick={() => setCompanyListSelector(CompanyListEnum.List)}>
          <div className="flex">
            <div className="uppercase pr-[5px]">Lists ({listStatistics.length})</div>
          </div>
        </button>
      </div>
    </div>
  );
}
