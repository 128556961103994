import IApiCaller, {
  IArticlesResponse,
  ICompaniesSummaryResponse,
  ISearchCompaniesResponse,
  IScoredCompaniesResponse,
  IThemesResponse,
} from '../types/api-caller';
import axios from 'axios';
import { API_BASE, API_ROUTES, getThemesPath } from '../constants/api';
import ICompany from '../types/company';
import { IToScoreCompany, IScoredUpload, IScoredUploadResponse } from '../types/scoring-company';
import IEmail from '../types/email';
import { IArticleWithDowJones } from '../types/article';
import { IContactDefaults, IContactRequest } from '../types/contact';
import { IValidateUserResponse, IPrivacyConsentRequest } from '../types/admin';
import IConfig from '../types/config';
import { getOktaTokenFromLocalStorage } from '../utils/local-storage';
import { ISaveCompanyUserList, IUserData, IUserSettingsRequest, UserInfo } from '../types/user-info';

export default function useApiCaller(): IApiCaller {
  const ARTICLES_PER_PAGE = 50;

  /**
   * Setup
   */

  const requestConfig = { headers: { Authorization: `Bearer ${getOktaTokenFromLocalStorage()}` } };
  const apiClient = axios.create({
    baseURL: API_BASE,
  });

  /**
   * Helpers
   */
  const _get = async (path: string) => {
    const response = await apiClient.get(path, requestConfig);
    return response.data;
  };

  const _post = async (path: string, params: { [x: string]: any } = {}) => {
    const response = await apiClient.post(path, params, requestConfig);
    return response.data;
  };

  /* _put is not supported by some clients including Lloyds  use post
  const _put = async (path: string, params: { [x: string]: any } = {}) => {
    const response = await apiClient.put(path, params, requestConfig);
    return response.data;
  };
  
  const _delete = async (path: string) => {
    const response = await apiClient.delete(path, requestConfig);
    return response.data;
  };
  */
  /**
   * Endpoints
   */
  const health = async () => {
    return _get(API_ROUTES.HEALTH);
  };

  const getPortfolio = async (): Promise<ICompaniesSummaryResponse> => {
    return _get(`${API_ROUTES.PORTFOLIO}`);
  };

  // const getPortfolioWithHistory = async (withHistory: boolean): Promise<ICompaniesResponse> => {
  //   return _get(`${API_ROUTES.PORTFOLIO_WITH_HISTORY}/${withHistory}`);
  // };

  const getCompany = async (companyId: string): Promise<ICompany> => {
    return _get(`${API_ROUTES.COMPANY}/${companyId}`);
  };

  const getArticles = async (
    companyId: string,
    sortCriteria: string,
    startDate: string,
    endDate: string,
    offset: number,
    limit: number = ARTICLES_PER_PAGE,
    filter: string = ''
  ): Promise<IArticlesResponse> => {
    let parameters = `limit=${limit}&offset=${offset}&sort_criteria=${sortCriteria}`;
    if (startDate !== '') {
      parameters = parameters.concat(`&start_date=${startDate}`.toString());
    }
    if (endDate !== '') {
      parameters = parameters.concat(`&end_date=${endDate}`.toString());
    }
    parameters = parameters.concat(`${filter ? `&filter=${filter}` : ''}`.toString());

    return _get(`${API_ROUTES.ARTICLES}/${companyId}?${parameters}`);
  };

  const getArticleWithDowJones = async (dowj_article_id: string): Promise<IArticleWithDowJones> => {
    return _get(`${API_ROUTES.ARTICLE}/${dowj_article_id}`);
  };

  const getThemes = async (
    companyId: string,
    startDate: string,
    endDate: string,
    offset: number,
    limit: number = ARTICLES_PER_PAGE
  ): Promise<IThemesResponse> => {
    let parameters = `limit=${limit}&offset=${offset}`;
    if (startDate !== '') {
      parameters = parameters.concat(`&start_date=${startDate}`);
    }
    if (endDate !== '') {
      parameters = parameters.concat(`&end_date=${endDate}`);
    }
    const themesPath = getThemesPath(companyId);
    return _get(`${themesPath}?${parameters}`);
  };

  const getThemeArticles = async (
    themeId: string,
    companyCode: string,
    offset: number,
    limit: number = ARTICLES_PER_PAGE
  ): Promise<IArticlesResponse> => {
    return _get(`${API_ROUTES.THEME_ARTICLES}?theme_id=${themeId}&company_code=${companyCode}&limit=${limit}&offset=${offset}`);
  };

  // const searchNotInPortfolio = async (term: string): Promise<ICompaniesResponse> => {
  //   return _get(`${API_ROUTES.SEARCH_NOT_IN_PORTFOLIO}?search_term=${encodeURIComponent(term)}`);
  // };

  const searchIncludingPortfolio = async (term: string, usingArticles: boolean): Promise<ISearchCompaniesResponse> => {
    if (usingArticles) {
      return _get(`${API_ROUTES.SEARCH_INCLUDING_PORTFOLIO_USING_ARTICLES}?search_term=${encodeURIComponent(term)}`);
    }
    return _get(`${API_ROUTES.SEARCH_INCLUDING_PORTFOLIO}?search_term=${encodeURIComponent(term)}`);
  };

  const startBulkScore = async (toScoreCompanies: IToScoreCompany[]): Promise<string> => {
    return _post(`${API_ROUTES.START_BULK_SCORE}`, toScoreCompanies);
  };

  const getBulkScore = async (taskID: string): Promise<IScoredCompaniesResponse> => {
    return _get(`${API_ROUTES.GET_BULK_SCORE}/${taskID}`);
  };

  const uploadUserCompany = async (toUploadCompanies: IScoredUpload[]): Promise<IScoredUploadResponse[]> => {
    return _post(`${API_ROUTES.UPLOAD_USER_COMPANY}`, toUploadCompanies);
  };

  const addToPortfolio = async (company_code: string): Promise<null> => {
    return _post(`${API_ROUTES.ADD_TO_PORTFOLIO}/${company_code}`);
  };

  const removeFromPortfolio = async (company_code: string): Promise<null> => {
    return _post(`${API_ROUTES.REMOVE_FROM_PORTFOLIO}/${company_code}`);
  };

  const getContactUsDefaults = async (): Promise<IContactDefaults> => {
    return _get(API_ROUTES.GET_CONTACT_US_DEFAULTS);
  };
  const postContactUs = async (contactUsRequest: IContactRequest): Promise<IContactRequest> => {
    return _post(API_ROUTES.POST_CONTACT_US, contactUsRequest);
  };

  // const downloadPortfolio = async (): Promise<String> => {
  //   return _get(API_ROUTES.DOWNLOAD);
  // };

  const getConfig = async (): Promise<IConfig> => {
    let environment = '';

    // See if there's a better way to do this
    if (window.location.href.includes('fss-lc.owex.oliverwyman.com')) {
      environment = 'dev';
    } else if (window.location.href.includes('fss.owex.oliverwyman.com')) {
      environment = 'prod';
    } else {
      environment = 'local';
    }

    return _get(`${API_ROUTES.CONFIG}?environment=${environment}`);
  };

  const validateUserWithInfo = async (user: UserInfo | undefined): Promise<IValidateUserResponse> => {
    return _post(API_ROUTES.VALIDATE_USER, user);
  };

  const savePrivacyConsent = async (consent: IPrivacyConsentRequest): Promise<null> => {
    return _post(API_ROUTES.PUT_PRIVACY_CONSENT, consent);
  };

  const sendTestEmail = async (): Promise<IEmail> => {
    return _post(API_ROUTES.SEND_TEST_EMAIL);
  };

  const sendEmail = async (): Promise<IEmail> => {
    return _post(API_ROUTES.SEND_EMAIL);
  };

  const saveEmailPreference = async (deliveryRate: string) => {
    return _post(API_ROUTES.SAVE_EMAIL_PREFERENCES, { delivery_rate: deliveryRate });
  };

  const getEmailPreference = async () => {
    return _get(API_ROUTES.GET_EMAIL_PREFERENCES);
  };

  const logout = async () => {
    return _post(API_ROUTES.LOGOUT);
  };

  const login = async () => {
    return _get(API_ROUTES.LOGIN);
  };

  const getAllUsers = async (): Promise<IUserData[]> => {
    return _get(API_ROUTES.GET_ALL_USERS);
  };

  const updateEmailPreferences = async (prefs: IUserSettingsRequest): Promise<any> => {
    return _post(API_ROUTES.UPDATE_EMAIL_PREFERENCES, prefs);
  };

  const saveCompanyUserList = async (company_code: string, user_list: string[]): Promise<any> => {
    const companyUserList: ISaveCompanyUserList = {
      company_code: company_code,
      user_list: user_list,
    };
    return _post(API_ROUTES.SAVE_COMPANY_USER_LIST, companyUserList);
  };

  return {
    ARTICLES_PER_PAGE,
    health,
    getPortfolio,
    // getPortfolioWithHistory,
    getCompany,
    getArticles,
    getArticleWithDowJones,
    getThemeArticles,
    // searchNotInPortfolio,
    searchIncludingPortfolio,
    startBulkScore,
    getBulkScore,
    uploadUserCompany,
    addToPortfolio,
    removeFromPortfolio,
    getContactUsDefaults,
    postContactUs,
    getConfig,
    savePrivacyConsent,
    validateUserWithInfo,
    getThemes,
    // downloadPortfolio,
    sendTestEmail,
    sendEmail,
    saveEmailPreference,
    getEmailPreference,
    logout,
    login,
    getAllUsers,
    updateEmailPreferences,
    saveCompanyUserList,
  };
}
