import React, { useState, useEffect } from 'react';
import useApiCaller from '../hooks/use-api-caller';
import Loading from './Loading';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { userInfoAtom } from '../store/user-info';
import { useRecoilState } from 'recoil';
import { NavLink } from 'react-router-dom';
import { superuserPermissionAtom } from '../store/config';

const EmailDeliverySelector = () => {
  const apiCaller = useApiCaller();

  const [selectedOption, setSelectedOption] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  // eslint-disable-next-line
  const is_superuser = useRecoilState(superuserPermissionAtom);

  const toggleShowAll = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setShowAll(!showAll);
  };

  const loadEmailPreference = async () => {
    try {
      setLoading(true);
      const response = await apiCaller.getEmailPreference();
      setSelectedOption(response.delivery_rate);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const saveEmailPreference = async (delivery_rate: string) => {
    try {
      if (loading) return;

      setLoading(true);
      await apiCaller.saveEmailPreference(delivery_rate);
      setSelectedOption(delivery_rate);
      setLoading(false);
      toast.success('Saved');
      setUserInfo({
        ...userInfo,
        email_delivery_rate: delivery_rate,
      });
    } catch (error) {
      toast.error('Not Saved');
      console.error('Error saving data:', error);
    }
  };
  useEffect(() => {
    loadEmailPreference();
  }, []);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveEmailPreference(event.target.value);
  };

  return (
    <form>
      <div>Below you can set the delivery frequency of your email notifications about companies that you have added to your portfolio.</div>
      {showAll ? (
        <div className="mt-4">
          You will be sent one aggregated daily or weekly email. The email will show: the Company names with FSS Score changes; the current
          FSS Score for the company; and, the Percentage change in the companies' FSS Scores. If there is no change to a company’s FSS Score
          they will not be shown in the email. A maximum of 20 companies trending negative and 20 companies trending positive will be shown
          in each email, prioritised by the biggest percentage changes.
        </div>
      ) : (
        ''
      )}{' '}
      <div className="mt-8">
        <button onClick={toggleShowAll} className="text-blue-500 hover:text-blue-700 focus:outline-none">
          {showAll ? 'Show less' : 'Show more'}
        </button>
      </div>
      <dl className="mt-8 divide-y divide-gray-200">
        <div className="flex flex-row space-x-4 pb-4">
          <input type="radio" name="emailDelivery" value="DAILY" checked={selectedOption === 'DAILY'} onChange={handleOptionChange} />
          <div className="radio-description">
            <div className="radio-head text-ow-primary text-lg font-bold">Daily</div>
            <div className="radio-body">
              A daily email showing companies from your portfolio that have seen a score change in the last 24 hour period. FSS Score %
              change is calculated for the last 24 hour period.
            </div>
          </div>
        </div>
        <div className="flex flex-row space-x-4 pt-4 pb-4">
          <input type="radio" name="emailDelivery" value="WEEKLY" checked={selectedOption === 'WEEKLY'} onChange={handleOptionChange} />
          <div className="radio-description">
            <div className="radio-head text-ow-primary text-lg font-bold">Weekly</div>
            <div className="radio-body">
              A weekly email showing companies from your portfolio that have seen a score change in the last 7 day period. FSS Score %
              change is calculated for the last 7 days period.
            </div>
          </div>
        </div>
        <div className="flex flex-row space-x-4 pt-4 pb-4">
          <input type="radio" name="emailDelivery" value="PAUSE" checked={selectedOption === 'PAUSE'} onChange={handleOptionChange} />
          <div className="radio-description">
            <div className="radio-head text-ow-primary text-lg font-bold">Unsubscribe</div>
            <div className="radio-body">Unsubscribe from all daily and weekly updates about your portfolio of clients.</div>
          </div>
        </div>
      </dl>
      {is_superuser && (
        <div className="mt-6">
          <div className="text-ow-primary text-lg font-bold">Subscription Editor</div>
          The email rate of other users can be maintained in the subscription editor page.
          <div className="mt-2">
            <NavLink to="/SubscriptionEditor">Go to Subscription Editor</NavLink>
          </div>
        </div>
      )}
      {loading && <Loading />}
    </form>
  );
};

export default EmailDeliverySelector;
