import { useEffect } from 'react';
import useCurrentPage from '../hooks/use-set-current-page';
import Container from '../components/Container';
import { useRecoilValue } from 'recoil';
import { ICompanySummary } from '../types/company';
import { companiesAtom, companyListSelectorAtom } from '../store/portfolio';
import Lists from '../components/Lists';
import Companies from '../components/Companies';
import { CompanyListEnum } from '../constants/company-list';

export default function Home() {
  const currentPage = useCurrentPage();
  useEffect(() => {
    currentPage.setCurrentPage('Portfolio');
  }, []);

  const companies = useRecoilValue<ICompanySummary[]>(companiesAtom);
  const companyListSelector = useRecoilValue<CompanyListEnum>(companyListSelectorAtom);

  return (
    <div className="mb-auto lg:bg-ow-lighter-grey">
      <Container>
        <div className="lg:flex lg:justify-center lg:w-full">
          <div className="lg:w-full lg:bg-white lg:p-8">
            <div>
              {companyListSelector === CompanyListEnum.Company && <Companies defaultList={[]} />}
              {companyListSelector === CompanyListEnum.List && <Lists noCompanies={companies.length === 0} />}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
