import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import useApiCaller from '../hooks/use-api-caller';
import ICompany, { ICompanySummary } from '../types/company';
import Loading from './Loading';
import PortfolioList from './PortfolioList';
import FSSError from './FSSError';
import CompareGraph from './CompareGraph';
import CompareTime from './CompareTime';
import CompareFilters from './CompareFilters';
import { ComparisonEnum } from '../constants/compare';
import { getUniqueCountries, getUniqueIndustries, areUserListsInCommon } from '../utils/compare-functions';
import { toast } from 'react-toastify';
import { companiesErrorAtom, companiesAtom, compareSelectedAtom, loadingCompaniesAtom, listsAtom } from '../store/portfolio';

export default function Companies({
  defaultList,
}: Readonly<{
  defaultList: string[];
}>) {
  const apiCaller = useApiCaller();

  const loadingCompanies = useRecoilValue<boolean>(loadingCompaniesAtom);
  const companiesError = useRecoilValue<boolean>(companiesErrorAtom);
  const companies = useRecoilValue<ICompanySummary[]>(companiesAtom);
  const lists = useRecoilValue<string[]>(listsAtom);

  const comparisonSelector = useRecoilValue<ComparisonEnum>(compareSelectedAtom);

  const [filterLists, setFilterLists] = useState<string[]>(defaultList ?? []);
  const [filterIndustries, setFilterIndustries] = useState<string[]>([]);
  const [filterCountries, setFilterCountries] = useState<string[]>([]);

  const [filteredCompanies, setFilteredCompanies] = useState<ICompanySummary[]>([]);

  const [industries, setIndustries] = useState<string[]>([]);
  const [countries, setCountries] = useState<string[]>([]);

  const [filteredHistory, setFilteredHistory] = useState<ICompany[]>([]);

  // ----------------------------------------------------------
  // History cache
  type HistoryCacheType = {
    [key: string]: ICompany;
  };

  const [historyCache, setHistoryCache] = useState<HistoryCacheType>({});

  const addToHistoryCache = (historyResults: ICompany[]) => {
    const localHistoryCache = { ...historyCache };
    historyResults.forEach((historyResult: ICompany) => {
      localHistoryCache[historyResult.company_code] = historyResult;
    });
    setHistoryCache(localHistoryCache);
  };

  // ----------------------------------------------------------
  // History loading
  const HISTORY_BATCH_SIZE = 5;
  const DELAY_SECONDS_BETWEEN_BATCH_SIZE = 1;

  const nonBlockingWait = (seconds: number) => new Promise((resolve) => setTimeout(resolve, seconds * 1000));

  const getOneHistory = async (company_code: string): Promise<ICompany> => {
    const oneHistory = await apiCaller.getCompany(company_code);
    return oneHistory;
  };

  // to avoid sending more requests to server than the server can handle in a short period of time
  //   also server limited in size of any one request
  //   for now, reuse the same company query
  //      TODO: future add a simplified query if needed
  const getHistoryInBatches = async (companies: ICompanySummary[], resultsAcrossBatches: ICompany[]) => {
    try {
      for (let i = 0; i < companies.length; i += HISTORY_BATCH_SIZE) {
        const batchResults = await Promise.all(
          companies.slice(i, i + HISTORY_BATCH_SIZE).map((company) => getOneHistory(company.company_code))
        );

        resultsAcrossBatches = resultsAcrossBatches.concat(batchResults);
        setFilteredHistory(resultsAcrossBatches);

        await nonBlockingWait(DELAY_SECONDS_BETWEEN_BATCH_SIZE);
      }
      addToHistoryCache(resultsAcrossBatches);
    } catch {
      toast.error('Failed to load history');
    }
  };

  const getHistoryUsingCache = () => {
    let companiesNotInCache: ICompanySummary[] = [];
    let cacheResults: ICompany[] = [];

    filteredCompanies.forEach((companySummary: ICompanySummary) => {
      if (companySummary.company_code in historyCache) {
        cacheResults.push(historyCache[companySummary.company_code]);
      } else {
        companiesNotInCache.push(companySummary);
      }
    });

    if (companiesNotInCache.length === 0) {
      //everything was found in cache
      setFilteredHistory(cacheResults);
    } else {
      getHistoryInBatches(companiesNotInCache, cacheResults);
    }
  };

  // ----------------------------------------------------------------------------
  //  filtering
  useEffect(() => {
    const filteredCompaniesLocal = companies
      .filter((company) => (filterLists.length > 0 ? areUserListsInCommon(filterLists, companies, company.company_code) : true))
      .filter((company) => (filterIndustries.length > 0 ? filterIndustries.includes(company.industry_sector) : true))
      .filter((company) => (filterCountries.length > 0 ? filterCountries.includes(company.country) : true));
    setFilteredCompanies(filteredCompaniesLocal);
  }, [companies, filterLists, filterCountries, filterIndustries]);

  useEffect(() => {
    getHistoryUsingCache();
  }, [filteredCompanies]);

  useEffect(() => {
    setCountries(getUniqueCountries(companies));
    setIndustries(getUniqueIndustries(companies));
  }, [companies]);

  const onUserListSelectionChange = (selectedList: string[]) => {
    setFilterLists(selectedList);
  };

  const onCountrySelectionChange = (selectedList: string[]) => {
    setFilterCountries(selectedList);
  };

  const onIndustrySelectionChange = (selectedList: string[]) => {
    setFilterIndustries(selectedList);
  };

  return (
    <div>
      {loadingCompanies && <Loading />}
      {companiesError && <FSSError />}
      {!loadingCompanies && !companiesError && companies.length !== 0 && (
        <div className="companies-list-container">
          <div className="pb-4">
            <CompareFilters
              lists={lists}
              industries={industries}
              countries={countries}
              onUserListSelectionChange={onUserListSelectionChange}
              onIndustrySelectionChange={onIndustrySelectionChange}
              onCountrySelectionChange={onCountrySelectionChange}
            />
          </div>
          <div>
            <div>
              {comparisonSelector === ComparisonEnum.Table && (
                <PortfolioList filteredCompanies={filteredCompanies} lists={lists} hideList={defaultList.length > 0} />
              )}
              {comparisonSelector === ComparisonEnum.Graph && (
                <CompareGraph filteredCompanies={filteredCompanies} allCompanies={companies} />
              )}
              {comparisonSelector === ComparisonEnum.Time && <CompareTime filteredCompaniesHistory={filteredHistory} />}
            </div>
          </div>
        </div>
      )}
      {!loadingCompanies && !companiesError && !companies.length && (
        <div>
          <p className="text-xs text-center">
            There are no companies in your portfolio, yet. To add a company, use the Search field in the top bar and then click the green
            "+" symbol to add your first company. If you need more assistance, please{' '}
            <NavLink to="/contact" className="text-ow-secondary">
              contact us
            </NavLink>{' '}
            .
          </p>
        </div>
      )}
    </div>
  );
}
