import Highcharts, { Options } from 'highcharts';
import noDataModule from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import { useRef } from 'react';
import { TooltipOptionsExtended } from '../types/tooltip-data';
import '../styles/CompanyGraphs.scss';
import '../extensions/highcharts-plugins';
import ICompany from '../types/company';
import { black, blue, GRAPH_FONT_SIZE, grey } from '../constants/company-chart';
import { createComparableHistoryCompanies, createComparableTimeDataSeries, getDataFromTimeRange } from '../utils/compare-time-functions';
import { breakPoints } from '../constants/downgrade-probability';
import { repositionTicks } from '../utils/company-chart-functions';
import { IntervalLengthConstants } from '../constants/interval-length-constants';
import { formatDateString } from '../utils/dates';

noDataModule(Highcharts);

export default function CompareTime({
  filteredCompaniesHistory,
}: Readonly<{
  filteredCompaniesHistory: ICompany[];
}>) {
  const ref = useRef<any>();
  const chartRef = useRef(null);

  const comparableHistoryCompanies = createComparableHistoryCompanies(filteredCompaniesHistory);
  const tooltipOptions: TooltipOptionsExtended = {
    useHTML: true,
    borderWidth: 0,
    backgroundColor: 'rgba(255,255,255,0)',
    shadow: false,
    // charts crash without this function
    remainOnMouseout: function () {
      return true;
    },
  };
  const firstDataItem = getDataFromTimeRange(comparableHistoryCompanies[0]);

  const options: Options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      animation: false,
      backgroundColor: 'transparent',
      height: 575,
      spacing: [10, 0, 0, 0],
    },
    credits: {
      enabled: false,
    },
    lang: {
      noData: '<div style="color:#FF0000">No companies match these filter criteria in your portfolio.</div>',
    },
    legend: {
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    noData: {
      useHTML: true,
    },
    series: createComparableTimeDataSeries(comparableHistoryCompanies),
    title: { text: '' },
    tooltip: tooltipOptions,
    // TODO: Investigate sharing y and x axis code with CompanyGraphs options
    // Currently to support the formatter, passing `this` into a shared function results in errors
    yAxis: [
      {
        title: {
          text: 'FSS Score',
          style: { color: black, fontSize: GRAPH_FONT_SIZE },
        },
        labels: {
          style: { color: black, fontSize: GRAPH_FONT_SIZE },
          formatter: function () {
            const label = this.axis.defaultLabelFormatter.call(this);
            const value = parseFloat(label);
            return `${value * 100}`;
          },
        },
        min: 0.0,
        max: 1.0,
        tickPositions: [0.0, 0.2, 0.4, 0.6, 0.8, 1.0],
        lineColor: blue,
        lineWidth: 4,
        plotLines: [
          { color: '#DADADA', value: breakPoints[1] },
          { color: '#DADADA', value: breakPoints[2] },
          { color: '#DADADA', value: breakPoints[3] },
          { color: '#DADADA', value: breakPoints[4] },
        ], // 'ow-light-grey': ,
        gridLineWidth: 0,
      },
    ],
    xAxis: {
      categories: firstDataItem.xAxisCategories,
      title: { text: '' },
      lineColor: grey,
      lineWidth: 1,
      tickPositioner: function () {
        return repositionTicks(this, firstDataItem);
      },
      tickmarkPlacement: 'on',
      tickWidth: 1,
      tickColor: grey,
      labels: {
        style: {
          color: black,
          fontSize: GRAPH_FONT_SIZE,
        },
        formatter: function () {
          const label = this.axis.defaultLabelFormatter.call(this);
          const positions = this.axis.tickPositions || [];

          if (
            this.isLast &&
            firstDataItem.daysInOneTick >= IntervalLengthConstants.Week &&
            positions.length > 2 &&
            Math.abs(this.axis.toPixels(this.axis.getExtremes().dataMax, false) - this.axis.toPixels(positions.at(-1)!, false)) <
              Math.abs(this.axis.toPixels(positions.at(-1)!, false) - this.axis.toPixels(positions.at(-2)!, false)) / 2
          ) {
            return '';
          }
          return formatDateString(label, firstDataItem.daysInOneTick);
        },
      },
    },
  };

  return (
    <div ref={ref}>
      <div className={`flex justify-end sm:justify-between mb-2 mt-2 lg:mt-0`}></div>
      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
    </div>
  );
}
