import _ from 'lodash';
import { useState } from 'react';
import Select, { MultiValue } from 'react-select';
import { convertToValueLabel, convertFromValueLabel } from '../utils/compare-functions';

export default function MultiSelectDropdown({
  options,
  placeHolder,
  onSelectionChange,
}: Readonly<{
  options: string[];
  placeHolder: string;
  onSelectionChange: (selectedList: string[]) => void;
}>) {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<{ value: string; label: string }>>([]);

  const handleChange = (selectedList: MultiValue<{ value: string; label: string }>) => {
    const selectedValueLabelSorted = _.orderBy(selectedList, 'label');
    setSelectedOptions(selectedValueLabelSorted);
    onSelectionChange(convertFromValueLabel(selectedValueLabelSorted));
  };

  return (
    <div style={{ width: '300px' }}>
      <Select isMulti value={selectedOptions} onChange={handleChange} options={convertToValueLabel(options)} placeholder={placeHolder} />
    </div>
  );
}
