import CompareSelector from './CompareSelector';
import MultiSelectDropdown from '../components/MultiSelectDropdown';

export default function CompareFilters({
  lists,
  industries,
  countries,
  onUserListSelectionChange,
  onIndustrySelectionChange,
  onCountrySelectionChange,
}: Readonly<{
  lists: string[];
  industries: string[];
  countries: string[];
  onUserListSelectionChange: (selectedList: string[]) => void;
  onIndustrySelectionChange: (selectedList: string[]) => void;
  onCountrySelectionChange: (selectedList: string[]) => void;
}>) {
  return (
    <div className="">
      <div className="flex justify-between text-xs">
        <MultiSelectDropdown options={countries} onSelectionChange={onCountrySelectionChange} placeHolder="Filter by countries.." />
        <div className="flex">
          <div className="hidden xl:inline mr-2">
            <div className="w-[300px]">
              <MultiSelectDropdown options={lists} onSelectionChange={onUserListSelectionChange} placeHolder="Filter by lists.." />
            </div>
          </div>
          <div className="hidden lg:inline">
            <div className="w-[300px] mr-2">
              <MultiSelectDropdown
                options={industries}
                onSelectionChange={onIndustrySelectionChange}
                placeHolder="Filter by industries.."
              />
            </div>
          </div>
          <div className="min-w-[2rem] sm:min-w-[18.5rem] text-left">
            <CompareSelector />
          </div>
        </div>
      </div>
    </div>
  );
}
