import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import Container from '../components/Container';
import useCurrentPage from '../hooks/use-set-current-page';
import Companies from '../components/Companies';
import PageListHeader from '../components/PageListHeader';
import { userListStatsAtom } from '../store/portfolio';
import { IListStatistics } from '../types/company';

export default function List() {
  const currentPage = useCurrentPage();
  const { myList } = useParams();

  const userListStats = useRecoilValue<IListStatistics[]>(userListStatsAtom);
  const [userListStat, setUserListStat] = useState<IListStatistics | undefined>(undefined);

  currentPage.setCurrentPage(`List - ${myList}`);

  useEffect(() => {
    if (userListStats.length === 0) {
      return;
    }
    const findUserListStat = userListStats.find((u) => u.listName === myList);
    setUserListStat(findUserListStat);
  }, [userListStats]);

  return (
    <div className="mb-auto lg:bg-ow-lighter-grey">
      <Container>
        {userListStat && <PageListHeader userListStat={userListStat} />}
        <div className="lg:flex justify-left lg:w-full">
          <div className="lg:w-full lg:bg-white lg:p-8">
            <Companies defaultList={[myList ?? '']} />
          </div>
        </div>
      </Container>
    </div>
  );
}
