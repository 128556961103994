import { useEffect } from 'react';
import _ from 'lodash';
// import { toast } from 'react-toastify';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ICompanySummary, IListStatistics } from '../types/company';
import useApiCaller from '../hooks/use-api-caller';
import { companiesErrorAtom, companiesAtom, loadingCompaniesAtom, listsAtom, userListStatsAtom } from '../store/portfolio';
import { addSortFieldToCompanies } from '../utils/add-sort-field-to-companies';
import { getUniqueUserList } from '../utils/compare-functions';
import { createUserListStats } from '../utils/user-list-functions';

export default function SharedData() {
  const apiCaller = useApiCaller();
  useEffect(() => {
    fetchPortfolio();
  }, []);

  const [loadingCompanies, setLoadingCompanies] = useRecoilState<boolean>(loadingCompaniesAtom);
  const setCompaniesError = useSetRecoilState<boolean>(companiesErrorAtom);
  const [companies, setCompanies] = useRecoilState<ICompanySummary[]>(companiesAtom);

  const setUserLists = useSetRecoilState<string[]>(listsAtom);
  const setUserListStats = useSetRecoilState<IListStatistics[]>(userListStatsAtom);

  const fetchPortfolio = () => {
    if (loadingCompanies || !!companies.length) return;

    setLoadingCompanies(true);

    apiCaller
      .getPortfolio()
      .then((res) => {
        const localCompanies = _.orderBy(addSortFieldToCompanies(res.items), 'current_score', 'desc');
        setCompanies(localCompanies);
      })
      .catch((err) => {
        console.error(err);
        setCompaniesError(true);
      })
      .finally(() => {
        setLoadingCompanies(false);
      });
  };

  //every time companies list changes, recalculate dependent lists
  useEffect(() => {
    const userListLocal = getUniqueUserList(companies);
    setUserLists(userListLocal);
    setUserListStats(createUserListStats(userListLocal, companies));
  }, [companies]);

  return <div></div>;
}
