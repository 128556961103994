import React, { useEffect, useRef, useState } from 'react';
import Button from './Button';

export default function CreateNewList({
  isOpen,
  onClose,
  onSubmit,
}: Readonly<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (inputValue: string) => void;
}>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleOnClose = () => {
    setInputValue('');
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(inputValue);
    handleOnClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-70 overflow-y-scroll z-30 flex justify-center items-start p-4">
      <div className="bg-white p-6 relative w-full lg:max-w-[75%] xl:max-w-[50%]">
        <button className="absolute top-3 right-3 p-3" onClick={handleOnClose}>
          <img src="/icons/close-blue.svg" alt="Close" />
        </button>
        <h2 className="font-mmc text-2xl pb-4 mb-4 text-ow-primary border-b border-ow-light-grey">New list</h2>
        <div className="pb-4 mb-4">Enter list name</div>
        <div className="pb-4 mb-4 ">
          <input
            ref={inputRef}
            type="text"
            className="w-full p-2 mb-4 border-2 border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
            value={inputValue}
            onChange={handleInputChange}
          />
        </div>
        <Button text="Submit" type="button" onClick={handleSubmit} />
      </div>
    </div>
  );
}
