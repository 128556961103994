import Highcharts, { Options } from 'highcharts';
import noDataModule from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import { useRef } from 'react';
import { TooltipOptionsExtended } from '../types/tooltip-data';
import '../styles/CompanyGraphs.scss';
import '../extensions/highcharts-plugins';
import { black, blue, GRAPH_FONT_SIZE } from '../constants/company-chart';
import { ICompanySummary } from '../types/company';
import {
  createComparablePercentCompanies,
  getPercentChartMinXMaxX,
  createComparableGraphDataSeries,
} from '../utils/compare-graph-functions';
import { breakPoints } from '../constants/downgrade-probability';

noDataModule(Highcharts);

export default function CompareGraph({
  filteredCompanies,
  allCompanies,
}: Readonly<{
  filteredCompanies: ICompanySummary[];
  allCompanies: ICompanySummary[];
}>) {
  const ref = useRef<any>();
  const chartRef = useRef(null);

  const tooltipOptions: TooltipOptionsExtended = {
    useHTML: true,
    borderWidth: 0,
    backgroundColor: 'rgba(255,255,255,0)',
    shadow: false,
    // charts crash without this function
    remainOnMouseout: function () {
      return false;
    },
  };

  const comparablePercentCompanies = createComparablePercentCompanies(filteredCompanies);
  const [chartMinX, chartMaxX] = getPercentChartMinXMaxX(createComparablePercentCompanies(allCompanies));

  const options: Options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      animation: true,
      backgroundColor: 'transparent',
      events: {
        load: function () {
          var chart = this;
          var yAxis = chart.yAxis[0];
          const NUMBER_OF_LABELS = 10;
          let label: any[] = Array(NUMBER_OF_LABELS).fill(null);

          function updateLabelPosition(labelIndex: number, name: string, yValueStart: number, yValueStop: number) {
            // put the label half way between the range
            const yValue = (yValueStop - yValueStart) / 2 + yValueStart;
            var textHeight = 16;
            // adjust for text height
            const yPosition = yAxis.toPixels(yValue, true) + textHeight;

            // If the label already exists, update its position
            if (label[labelIndex]) {
              if (yPosition < chart.plotTop + chart.plotHeight) {
                label[labelIndex].attr({
                  y: yPosition,
                });
              } else {
                // label would no longer fit in chart, remove it
                label[labelIndex].destroy();
                label[labelIndex] = null;
              }
            } else {
              // Create the label if it doesn't exist
              label[labelIndex] = chart.renderer
                .text(
                  name,
                  80, // x-coordinate (close to the y-axis)
                  yPosition // y-coordinate based on y-value 50
                )
                .css({
                  color: '#DADADA',
                  fontSize: '12px',
                })
                .add();
            }
          }

          function updateLabelsPosition() {
            // each command needs a different label index up to NUMBER_OF_LABELS
            updateLabelPosition(0, 'Very low', breakPoints[0], breakPoints[1]);
            updateLabelPosition(1, 'Low', breakPoints[1], breakPoints[2]);
            updateLabelPosition(2, 'Moderate', breakPoints[2], breakPoints[3]);
            updateLabelPosition(3, 'High', breakPoints[3], breakPoints[4]);
            updateLabelPosition(4, 'Very high', breakPoints[4], 100);
          }

          // Initial positioning of the label
          updateLabelsPosition();

          // Update the label position on chart redraw (e.g., zooming)
          Highcharts.addEvent(chart, 'redraw', updateLabelsPosition);
        },
      },
      height: 575,
      spacing: [10, 0, 0, 0],
      type: 'scatter',
      zooming: {
        type: 'xy',
      },
    },
    credits: {
      enabled: false,
    },
    lang: {
      noData: '<div style="color:#FF0000">No companies in your portfolio with valid scores match these filter criteria.</div>',
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    noData: {
      useHTML: true,
    },

    plotOptions: {
      scatter: {
        marker: { enabled: true, radius: 5, symbol: 'circle' },
        lineWidth: 0,
        dataLabels: {
          align: 'right',
          enabled: true,
          padding: 10,
          formatter: function () {
            return this.point.name;
          },
          verticalAlign: 'middle',
        },
        point: {
          events: {
            click: function (p: any) {
              const company_code = p.point.options.company_code;
              // eslint-disable-next-line no-restricted-globals
              location.href = `company/${company_code}`;
            },
          },
        },
        tooltip: {
          headerFormat: '',
          pointFormat: `
      <div style="background: #fff;border: 1px solid #dadada;min-width: 140px; border-radius: 10px;z-index: 20;">
        <div style="color:#FFF;background: black;padding: 1px;height: 26px;text-align: center;line-height: 26px;border-top-left-radius: 10px;border-top-right-radius: 10px;padding-left:10px;padding-right:10px;">
          {point.name}
        </div>
        <div style="display:flex; justify-content:center;padding:10px;">
          <table >
            <tr >
              <td style="">Weekly %:</td>
              <td style="width: 50px; font-size: 20px;font-weight: 700;line-height: 18px;text-align:right;">{point.x: .1f}%</td>
            </tr>
            <tr>
              <td style="">Score:</td>
              <td style="width: 50px;color:#009de0; font-size: 20px;font-weight: 700;line-height: 18px;text-align:right;">{point.y: .0f}<span style="color:#FFFFFF;">.0%</span></td>
            </tr>
          </table>
        </div>
      </div>
      `,
        },
      },
    },
    series: createComparableGraphDataSeries(comparablePercentCompanies),
    title: { text: '' },
    tooltip: tooltipOptions,
    xAxis: [
      {
        max: chartMaxX,
        min: chartMinX,
        title: { text: 'Weekly % FSS score change', style: { color: black, fontSize: GRAPH_FONT_SIZE } },
      },
    ],
    yAxis: [
      {
        title: {
          text: 'FSS Score (0-100)',
          style: { color: black, fontSize: GRAPH_FONT_SIZE },
        },
        labels: {
          style: { color: black, fontSize: GRAPH_FONT_SIZE },
        },
        min: 0,
        max: 100,
        lineColor: blue,
        lineWidth: 4,
        plotLines: [
          { color: '#DADADA', value: breakPoints[1] },
          { color: '#DADADA', value: breakPoints[2] },
          { color: '#DADADA', value: breakPoints[3] },
          { color: '#DADADA', value: breakPoints[4] },
        ], // 'ow-light-grey': ,
        gridLineWidth: 0,
      },
    ],
  };

  return (
    <div ref={ref}>
      <div className={`flex justify-end sm:justify-between mb-2 mt-2 lg:mt-0`}></div>

      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
    </div>
  );
}
