import Highcharts from 'highcharts';
import { orderBy } from 'lodash';
import ICompany from '../types/company';
import { SeriesData } from './series-data';
import { SizeChangeDetector } from './size-change-detector';

export const createComparableHistoryCompanies = (companies: ICompany[]): ICompany[] => {
  return orderBy(companies, ['name'], ['asc']);
};

export const getDataFromTimeRange = (company: ICompany) => {
  const seriesData = new SeriesData(company.time_series);

  // TODO: Use selected page date range - ERP-1391
  const pageDateRange = {
    value: '12m',
    label: 'Recent 5 years',
    earliestDate: '2023-10-04',
    latestDate: '2024-10-04',
  };

  const sizeChangeDetector = new SizeChangeDetector();
  const daysInOneBar = sizeChangeDetector.getLevelDaysByRange(pageDateRange);
  return seriesData.calculateDataForTimerange(pageDateRange, daysInOneBar);
};

export const createComparableTimeDataSeries = (companies: ICompany[]): Highcharts.SeriesOptionsType[] => {
  return companies
    .map((company: any) => {
      const dataFromTimeRange = getDataFromTimeRange(company);
      return [dataFromTimeRange.series[0], dataFromTimeRange.series[1]];
    })
    .flat();
};
