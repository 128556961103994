import _ from 'lodash';
import { MultiValue } from 'react-select';
import ICompany, { ICompanySummary, ITimeseries } from '../types/company';

export const convertToValueLabel = (myList: string[]): MultiValue<{ value: string; label: string }> => {
  const myOrderedList: string[] = _.orderBy(_.uniq(myList), [(u) => _.toLower(u)], ['asc']);
  return myOrderedList.map((item) => {
    return { value: item, label: item };
  });
};

export const convertFromValueLabel = (myValueLabels: MultiValue<{ value: string; label: string }>): string[] => {
  const myValues: string[] = myValueLabels.map((item) => {
    return item.value;
  });
  return _.orderBy(_.uniq(myValues), [(u) => _.toLower(u)], ['asc']);
};

const ADD_NEW_STRING = 'Create new list...';
export const ADD_NEW_VALUE = 'add-new';

export const addNewOption = (initialList: MultiValue<{ value: string; label: string }>): MultiValue<{ value: string; label: string }> => {
  const existingObject = initialList.find((item) => item.value === ADD_NEW_VALUE);
  if (!existingObject) {
    return [...initialList, { label: ADD_NEW_STRING, value: ADD_NEW_VALUE }];
  }
  return initialList;
};

export const removeNewOption = (
  initialList: MultiValue<{ value: string; label: string }>
): MultiValue<{ value: string; label: string }> => {
  return initialList.filter((item) => item.value !== ADD_NEW_VALUE);
};

export const getUniqueCountries = (companies: ICompanySummary[]): string[] => {
  return _.orderBy(Object.keys(_.groupBy(companies, 'country')), [(u) => _.toLower(u)], ['asc']);
};

export const getUniqueIndustries = (companies: ICompanySummary[]): string[] => {
  return _.orderBy(Object.keys(_.groupBy(companies, 'industry_sector')), [(u) => _.toLower(u)], ['asc']);
};

export const getUniqueUserList = (companies: ICompanySummary[]): string[] => {
  const listOfUserLists = companies.map((company) => {
    return company.user_list;
  });

  const combinedList = listOfUserLists.reduce((acc, currentList) => [...acc, ...currentList], []);
  return _.orderBy(_.uniq(combinedList), [(u) => _.toLower(u)], ['asc']);
};

export const areUserListsInCommon = (oneList: string[], companies: ICompanySummary[], company_code: string): boolean => {
  const findCompany: ICompanySummary[] = companies.filter((company) => company.company_code === company_code) ?? [];
  if (oneList.length === 0 || findCompany.length === 0) {
    return false;
  }
  const secondList = findCompany[0].user_list;

  const commonStrings = oneList.filter((item) => secondList.includes(item));
  return commonStrings.length > 0;
};

const convertToDictionary = (history: ICompany[]): { [key: string]: ITimeseries[] } => {
  const timeSeriesByCompany: { [key: string]: ITimeseries[] } = {};
  history.forEach((company: ICompany) => {
    timeSeriesByCompany[company.company_code] = company.time_series;
  });
  return timeSeriesByCompany;
};

export const addInHistory = (localCompanies: ICompany[], history: ICompany[]) => {
  const historyByCompany = convertToDictionary(history);
  const localCompaniesWithHistory = localCompanies.map((company) => {
    company.time_series = historyByCompany[company.company_code] ?? [];
    return company;
  });
  return localCompaniesWithHistory;
};

export function compareNames(stringA: string, stringB: string, sortDirection: string): number {
  if (sortDirection === 'DOWN') {
    return stringB.localeCompare(stringA);
  }
  return stringA.localeCompare(stringB);
}
