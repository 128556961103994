import { useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useRecoilState, useSetRecoilState } from 'recoil';
import FtuConsent from './components/FtuConsent';
import useApiCaller from './hooks/use-api-caller';
import { superuserPermissionAtom, userGroupAtom, configAtom } from './store/config';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import Routes from './okta/Routes';
import { getOktaAuthServerId, getOktaClientId, getOktaDomain, isOktaEnabled, getFeatureFlags } from './utils/config';
import { dataDogInit } from './utils/data-dog-logging';
import { ToastContainer } from 'react-toastify';
import IConfig from './types/config';
import IdleWatcher from './components/IdleWatcher';
import SharedData from './components/SharedData';

const featureFlags = getFeatureFlags();
const oktaAuth = new OktaAuth({
  issuer: `https://${getOktaDomain()}/oauth2/${getOktaAuthServerId()}`,
  clientId: getOktaClientId(),
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ['openid', 'email', 'profile'],
});

if (featureFlags.has('test_feature_flags')) {
  console.log(`testing feature flags ${Array.from(featureFlags)}`);
}

export default function App() {
  const apiCaller = useApiCaller();
  const navigate = useNavigate();
  const location = useLocation();
  const hasBlueBg = location.pathname === '/login' || location.pathname === '/forgot-password';
  const [configStored, setConfigStored] = useRecoilState(configAtom);

  const setUserGroup = useSetRecoilState(userGroupAtom);
  const setSuperuserPermission = useSetRecoilState(superuserPermissionAtom);

  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const getBgClass = () => {
    if (location.pathname === '/login' || location.pathname === '/forgot-password') return 'bg-ow-primary';
    if (hasBlueBg) return 'bg-ow-primary lg:bg-ow-lighter-grey';
    return 'bg-white lg:bg-ow-lighter-grey';
  };

  const oktaEnabled = isOktaEnabled();

  /**
   * Initialise Google Analytics + Datadog
   */
  const initAnalytics = async () => {
    if (!configStored) {
      const localConfig: IConfig = await apiCaller.getConfig();
      setConfigStored(localConfig);
      if (localConfig.ga_tracking_code) {
        ReactGA.initialize(localConfig.ga_tracking_code);
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
      }
      dataDogInit();

      if (localConfig.user_group) {
        setUserGroup(localConfig.user_group);
      }

      setSuperuserPermission(localConfig.is_superuser);
    }
  };

  useEffect(() => {
    initAnalytics();
  });

  return (
    <div>
      <div>
        <SharedData />
        <ToastContainer position="bottom-right" />
      </div>{' '}
      {oktaEnabled ? (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <div className={`flex flex-col h-screen justify-between ${getBgClass()}`}>
            <Header></Header>
            <Routes />

            <Footer></Footer>
            {location.pathname !== '/login' && <IdleWatcher></IdleWatcher>}

            {location.pathname !== '/login' && <FtuConsent />}
          </div>
        </Security>
      ) : (
        <div className={`flex flex-col h-screen justify-between ${getBgClass()}`}>
          <Header></Header>
          <Routes />

          <Footer></Footer>
          <IdleWatcher></IdleWatcher>
        </div>
      )}
    </div>
  );
}
