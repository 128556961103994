import { ComparisonEnum } from '../constants/compare';
import { useRecoilState } from 'recoil';
import { compareSelectedAtom } from '../store/portfolio';

export default function CompareSelector() {
  const [comparisonSelector, setComparisonSelector] = useRecoilState<ComparisonEnum>(compareSelectedAtom);

  const tableClassName = `pr-[17px] ${comparisonSelector === ComparisonEnum.Table ? 'text-ow-secondary' : 'black'}`;
  const graphClassName = `pr-[17px] ${comparisonSelector === ComparisonEnum.Graph ? 'text-ow-secondary' : 'black'}`;
  const timeClassName = `pr-[17px] ${comparisonSelector === ComparisonEnum.Time ? 'text-ow-secondary' : 'black'}`;
  const tableImg = `/icons/compare-table${comparisonSelector === ComparisonEnum.Table ? '-selected' : ''}.svg`;
  const tableGraph = `/icons/compare-graph${comparisonSelector === ComparisonEnum.Graph ? '-selected' : ''}.svg`;
  const tableTime = `/icons/compare-time${comparisonSelector === ComparisonEnum.Time ? '-selected' : ''}.svg`;
  return (
    <div>
      <div className="flex justify-end font-bold">
        <button className={tableClassName} onClick={() => setComparisonSelector(ComparisonEnum.Table)}>
          <div className="flex">
            <div className="uppercase pr-[5px]">Table</div>
            <div>
              <img src={tableImg} alt={'Table'} />
            </div>
          </div>
        </button>
        <button className={graphClassName} onClick={() => setComparisonSelector(ComparisonEnum.Graph)}>
          <div className="flex">
            <div className="uppercase pr-[5px]">Graph</div>
            <div className="pt-1">
              <img src={tableGraph} alt={'Graph'} />
            </div>
          </div>
        </button>
        <button className={timeClassName} onClick={() => setComparisonSelector(ComparisonEnum.Time)}>
          <div className="flex">
            <div className="uppercase pr-[5px]">Time</div>
            <div className="pt-1">
              <img src={tableTime} alt={'Time'} />
            </div>
          </div>
        </button>
      </div>
    </div>
  );
}
