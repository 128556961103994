import { ISearchedCompany } from '../types/company';

export const scoreCompanies = (companies: ISearchedCompany[], searchTerm: string): ISearchedCompany[] => {
  const wholeWordAnyWhere = new RegExp(`\\b${searchTerm}\\b`, 'i');
  const anywhere = new RegExp(`${searchTerm}`, 'i');

  const scoreCompanyName = (name: string): number => {
    const wholeWordMatch = wholeWordAnyWhere.exec(name);
    if (wholeWordMatch) {
      return wholeWordMatch.index;
    }
    const somewhereMatch = anywhere.exec(name);
    if (somewhereMatch) {
      return 1000;
    }
    //shouldn't occur because all items in list should have the item somewhere
    return 2000;
  };

  return companies.map((company: ISearchedCompany) => {
    company.score = scoreCompanyName(company.name);
    return company;
  });
};
