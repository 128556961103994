import { getApiUrl, isExternalRoutes } from '../utils/config';

export const API_BASE = getApiUrl();
const externalRoutes = isExternalRoutes();

// Routes to use when running locally to call a local API, bypassing API Gateway
const LOCAL_API_ROUTES = {
  HEALTH: 'health',
  PORTFOLIO: 'portfolio',
  // PORTFOLIO_WITH_HISTORY: 'portfolio',
  COMPANY: 'company',
  ARTICLES: 'articles',
  ARTICLE: 'article',
  THEME_ARTICLES: 'articles/theme',
  SEARCH_NOT_IN_PORTFOLIO: 'search',
  SEARCH_INCLUDING_PORTFOLIO: 'search/including_portfolio',
  SEARCH_INCLUDING_PORTFOLIO_USING_ARTICLES: 'search/using_articles',
  START_BULK_SCORE: 'search/bulk',
  GET_BULK_SCORE: 'search/bulk',
  UPLOAD_USER_COMPANY: 'user_company/upload',
  SAVE_COMPANY_USER_LIST: 'user_company/user_list',
  ADD_TO_PORTFOLIO: 'edit-portfolio/add',
  REMOVE_FROM_PORTFOLIO: 'edit-portfolio/remove',
  GET_CONTACT_US_DEFAULTS: 'contact-us',
  POST_CONTACT_US: 'contact-us',
  // DOWNLOAD: 'download',
  CONFIG: 'config',
  VALIDATE_USER: 'admin/validate-user',
  PUT_PRIVACY_CONSENT: 'privacy-consent',
  SEND_TEST_EMAIL: 'mail/test',
  SEND_EMAIL: 'mail/send',
  SAVE_EMAIL_PREFERENCES: 'user/emailPreference',
  GET_EMAIL_PREFERENCES: 'user/emailPreference',
  UPDATE_EMAIL_PREFERENCES: 'user/updateUserEmailPreference',
  LOGOUT: 'admin/logout',
  LOGIN: 'admin/login',
  GET_ALL_USERS: 'user/allUsers',
};

// Routes to use in production to call API Gateway
const API_GATEWAY_ROUTES = {
  HEALTH: 'health',
  PORTFOLIO: 'getPortfolio',
  // PORTFOLIO_WITH_HISTORY: 'getPortfolioWithHistory',
  COMPANY: 'getCompany',
  ARTICLES: 'getArticles',
  ARTICLE: 'getArticle',
  THEME_ARTICLES: 'getThemeArticles',
  SEARCH_NOT_IN_PORTFOLIO: 'search',
  SEARCH_INCLUDING_PORTFOLIO: 'searchIncludingPortfolio',
  SEARCH_INCLUDING_PORTFOLIO_USING_ARTICLES: 'searchUsingArticles',
  START_BULK_SCORE: 'startScoreInBulk',
  GET_BULK_SCORE: 'getScoreInBulk',
  UPLOAD_USER_COMPANY: 'uploadUserCompany',
  SAVE_COMPANY_USER_LIST: 'saveCompanyUserList',
  ADD_TO_PORTFOLIO: 'addToPortfolio',
  REMOVE_FROM_PORTFOLIO: 'removeFromPortfolio',
  GET_CONTACT_US_DEFAULTS: 'getContactUsDefaults',
  POST_CONTACT_US: 'postContactUs',
  // DOWNLOAD: 'downloadPortfolio',
  CONFIG: 'config',
  VALIDATE_USER: 'validateUser',
  PUT_PRIVACY_CONSENT: 'savePrivacyConsent',
  SEND_TEST_EMAIL: 'testEmail',
  SEND_EMAIL: 'sendEmail',
  SAVE_EMAIL_PREFERENCES: 'user/emailPreference',
  GET_EMAIL_PREFERENCES: 'user/emailPreference',
  UPDATE_EMAIL_PREFERENCES: 'user/updateUserEmailPreference',
  LOGOUT: 'logout',
  LOGIN: 'login',
  GET_ALL_USERS: 'user/allUsers',
};

export const getThemesPath = (companyId: string): string => {
  return externalRoutes ? `getThemes/${companyId}` : `articles/${companyId}/themes`;
};

export const API_ROUTES = externalRoutes ? API_GATEWAY_ROUTES : LOCAL_API_ROUTES;
