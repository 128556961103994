import { useState } from 'react';
import Select, { MultiValue } from 'react-select';
import { ADD_NEW_VALUE, convertToValueLabel, convertFromValueLabel, addNewOption, removeNewOption } from '../utils/compare-functions';
import CreateNewList from '../components/CreateNewList';

export default function MultiSelectAddNewItemDropdown({
  options,
  initialUserList,
  placeHolder,
  onSelectionChange,
}: Readonly<{
  options: string[];
  initialUserList: string[];
  placeHolder: string;
  onSelectionChange: (selectedList: string[]) => void;
}>) {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<{ value: string; label: string }>>(
    convertToValueLabel(initialUserList)
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onModalClose = (): void => {
    setIsModalOpen(false);
  };

  const addToSelectedList = (cloneSelectedList: any) => {
    // conversion also sorts and makes unique
    const selectedValueLabelSorted = convertToValueLabel(convertFromValueLabel(removeNewOption(cloneSelectedList)));
    setSelectedOptions(selectedValueLabelSorted);
    onSelectionChange(convertFromValueLabel(selectedValueLabelSorted));
  };

  const onModalSubmit = (newLabel: string): void => {
    setIsModalOpen(false);

    //remove csv challenging values
    newLabel = newLabel.trim().replace(/'/g, '').replace(/"/g, '').replace(/,/g, '');
    if (newLabel.length > 0) {
      let cloneSelectedList = [...selectedOptions];
      cloneSelectedList.push({ value: newLabel, label: newLabel });
      addToSelectedList(cloneSelectedList);
    }
  };

  const handleChange = (selectedList: MultiValue<{ value: string; label: string }>) => {
    const index = selectedList.findIndex((item) => item.value === ADD_NEW_VALUE);
    let cloneSelectedList = [...selectedList];
    if (index !== -1) {
      setIsModalOpen(true);
      return;
    }

    addToSelectedList(cloneSelectedList);
  };

  return (
    <div style={{ width: '300px' }}>
      <Select
        isMulti
        value={selectedOptions}
        onChange={handleChange}
        options={addNewOption(convertToValueLabel(options))}
        placeholder={placeHolder}
      />
      <CreateNewList isOpen={isModalOpen} onClose={onModalClose} onSubmit={onModalSubmit} />
    </div>
  );
}
